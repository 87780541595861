import { ILogin } from '@/types/interface';
import { apiClient } from './apiService';

export const authService = {
  login(data: ILogin) {
    return apiClient.request({
      method: 'POST',
      url: '/auth/partner/login',
      data,
    });
  },

  getProfile() {
    return apiClient.request({
      method: 'POST',
      url: '/partner/profile',
    });
  },

  updateProfile(data: {
    fullName?: string;
    address?: string;
    phone1?: string;
    phone2?: string;
    phone3?: string;
  }) {
    return apiClient.request({
      method: 'PATCH',
      url: '/partner/profile',
      data,
    });
  },
};
