export const handleLocalStorage = {
  set(name: string, val: any) {
    localStorage.setItem(name, JSON.stringify(val));
  },

  get(name: string) {
    return JSON.parse(localStorage.getItem(name)!);
  },

  remove(name: string) {
    localStorage.removeItem(name);
  },
};
