import { createTheme } from '@mui/material';
import { myColor } from './color';

const DefaultTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#d0872a',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#0D6EFD',
    },
    success: {
      main: '#025282',
    },
    error: {
      main: '#C22625',
    },
    warning: {
      main: '#FF9F43',
    },
    info: {
      main: '#00CFE8',
    },
    background: {
      default: '#F2F2F2',
    },
    ...myColor,

    // neutral: {},
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          '&:disabled': {
            // opacity: 0.3,
          },
        },
      },
      variants: [
        {
          props: {
            variant: 'outlined',
            color: 'inherit',
          },
          style: {
            borderColor: '#a2a7b0',
            color: '#9da8bd',
          },
        },
        {
          props: {
            size: 'large',
          },
          style: {
            padding: '14px 136px',
            height: '48px',
          },
        },
        {
          props: {
            size: 'medium',
          },
          style: {
            padding: '11px 37.5px',
            height: '48px',
          },
        },
        {
          props: {
            size: 'small',
          },
          style: {
            padding: '12px 20px',
            height: '40px',
          },
        },
      ],
    },
  },

  typography: {
    fontFamily: ['"Roboto", "sans-serif"'].join(','),

    h1: {
      fontSize: '56px',
      lineHeight: '66px',
    },
    h2: {
      fontSize: '20px',
      lineHeight: '23px',
    },
    h3: {
      fontSize: '18px',
      lineHeight: '22px',
    },
    h4: {
      fontSize: '16px',
      lineHeight: '20px',
    },
    h5: {
      fontSize: '14px',
      lineHeight: '16px',
    },
    h6: {
      fontSize: '12px',
      lineHeight: '14px',
    },
    body1: {
      fontSize: '16px',
      lineHeight: '20px',
    },
    body2: {
      fontSize: '14px',
      lineHeight: '22px',
    },
    body3: {
      fontSize: '16px',
      lineHeight: '20px',
    },
    body4: {
      fontSize: '14px',
      lineHeight: '18px',
    },
    body5: {
      fontSize: '12px',
      lineHeight: '15px',
    },
    body6: {
      fontSize: '10px',
      lineHeight: '13px',
    },
    fontWeightBold: 900,
    fontWeightMedium: 500,
    fontWeightRegular: 400,
    fontWeightLight: 300,
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1218,
      xl: 1440,
    },
  },
});

DefaultTheme.typography = {
  ...DefaultTheme.typography,
  body2: {
    ...DefaultTheme.typography.body2,
    [DefaultTheme.breakpoints.down('md')]: {
      ...DefaultTheme.typography.body3,
    },
    [DefaultTheme.breakpoints.down('sm')]: {
      ...DefaultTheme.typography.body4,
    },
  },
  body3: {
    ...DefaultTheme.typography.body3,
    [DefaultTheme.breakpoints.down('sm')]: {
      ...DefaultTheme.typography.body4,
    },
  },
  body4: {
    ...DefaultTheme.typography.body4,
    [DefaultTheme.breakpoints.down('sm')]: {
      ...DefaultTheme.typography.body5,
    },
  },
};

export default DefaultTheme;
