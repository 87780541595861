/**
 * @type {import('next-i18next').UserConfig}
 */
module.exports = {
  debug: process.env.NODE_ENV === 'development',
  // reloadOnPrerender: process.env.NODE_ENV === 'development',
  i18n: {
    locales: ['en', 'vi'],
    defaultLocale: 'en',
    localeDetection: false,
  },
  localePath:
    typeof window === 'undefined'
      ? require('path').resolve('./public/locales')
      : '/locales',
  serializeConfig: false,
  cache: {
    enabled: true,
    expirationTime: 7 * 24 * 60 * 60 * 1000,
  },
};
