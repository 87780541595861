import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

export default function PreLoading({
  backgroundColor,
}: {
  backgroundColor?: string;
}) {
  return (
    <Backdrop
      open={true}
      sx={{
        backgroundColor: backgroundColor ?? 'unset',
        zIndex: theme => theme.zIndex.drawer + 1,
      }}
    >
      <CircularProgress value={50} />
    </Backdrop>
  );
}
