import { withLenses } from '@dhmk/zustand-lens';
import create, { StateCreator } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { StoreSlice } from './slice';
import { createGlobalSlice } from './global';

const myDevTools = (slices: StateCreator<StoreSlice>) =>
  create<StoreSlice>()(
    immer(
      devtools(withLenses(slices), {
        enabled:
          process.env.NODE_ENV === 'development' &&
          typeof window !== 'undefined',
      }),
    ),
  );

const useStoreApp = myDevTools(() => ({
  globalSlice: createGlobalSlice,
}));

export default useStoreApp;
