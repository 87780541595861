import { authService } from '@/services/authService';
import useStoreApp from '@/store';
import { GlobalSlice } from '@/store/global/type';
import { handleLocalStorage } from '@/utils/handleLocalStorage';
import { useQuery } from 'react-query';

export default function useProfile() {
  const { setUser }: GlobalSlice = useStoreApp(state => state.globalSlice);

  return useQuery(
    'getProfile',
    async () => {
      try {
        const token = handleLocalStorage.get('accessToken')!;
        if (!token) throw Error('No access token!!!');

        const { data } = await authService.getProfile();

        return data;
      } catch (error: any) {
        throw Error(error);
      }
    },
    {
      staleTime: 2 * 60 * 1000,
      onSuccess: data => setUser(data),
      onError: () => {
        handleLocalStorage.remove('accessToken');
        setUser(null);
      },
    },
  );
}
