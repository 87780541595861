import useProfile from '@/queries/useProfile';
import { setBearerToken } from '@/services/apiService';
import { handleLocalStorage } from '@/utils/handleLocalStorage';
import React, { useEffect } from 'react';
import PreLoading from '../PreLoading';

function NormalRoute({ children }: any) {
  if (typeof window !== 'undefined') {
    setBearerToken(handleLocalStorage.get('accessToken')!);
  }

  const { status } = useProfile();

  useEffect(() => {
    if (status === 'loading') return;
  }, [status]);

  if (status !== 'loading') return children;

  return <PreLoading />;
}

export default NormalRoute;
