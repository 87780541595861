import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import * as Sentry from '@sentry/nextjs';

const apiClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL!,
  timeout: 30000,
});

// Request interceptor
apiClient.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    config.headers.common = {
      ['Content-Type']: 'application/json',
      Accept: 'application/json',
    };

    return config;
  },
  (error: AxiosError) => {
    Sentry.captureException(error);
    return Promise.reject(error);
  },
);

// Response interceptor
apiClient.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    Sentry.captureException(error);
    return Promise.reject(error);
  },
);

const setBearerToken = (token?: string | null) => {
  if (!!token) {
    apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete apiClient.defaults.headers.common['Authorization'];
  }
};

export { apiClient, setBearerToken };
