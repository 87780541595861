export const myColor = {
  black: {
    main: '#273552',
    10: '#495871',
    20: '#A2AEBD',
  },

  white: {
    main: '#FFFFFF',
    10: '#E7EAEF',
    20: '#DBDFEF',
    30: '#F5F6F8',
    40: '#F5F9FF',
    50: '#EBEFF7',
  },

  red: {
    main: '#EA5455',
  },

  other: {
    10: '#FFF7E5',
    20: '#FCF2DE',
  },

  hightlight: {
    main: '#2C68DE',
    10: '#025282',
  },
};
