import useProfile from '@/queries/useProfile';
import { setBearerToken } from '@/services/apiService';
import { handleLocalStorage } from '@/utils/handleLocalStorage';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import PreLoading from '../PreLoading';

function PublicRoute({ children }: any) {
  if (typeof window !== 'undefined') {
    setBearerToken(handleLocalStorage.get('accessToken')!);
  }

  const router = useRouter();
  const { status } = useProfile();

  useEffect(() => {
    if (status === 'loading') return;

    if (status === 'success') {
      router.push('/dashboard');
    }
  }, [status]);

  if (status === 'error') {
    return children;
  }

  return <PreLoading />;
}

export default PublicRoute;
